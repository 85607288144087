var site = site || {};

site.template = site.template || {};

(function ($, Drupal, window, document) {
  var attached = false;
  var lastBP;
  var qsContainerActiveClasses = function () {
    var classes = 'active';

    for (var i = 1; i <= 10; i++) {
      classes += ' active--' + i;
    }

    return classes;
  };

  Drupal.behaviors.customCollectionProductQuickshopV1 = {
    attach: function (context) {
      if (attached) {
        return;
      }

      attached = true;

      this.context = context;

      this.setEvents();
    },

    initGrid: function ($grids, size) {
      if (_.isUndefined(size)) {
        size = Unison.fetch.now().name;
      }

      var isMobile = !_.isUndefined(size) && size.indexOf('small') !== -1;
      var self = this;

      $grids.has('.js-product-grid-item').each(function () {
        var $grid = $(this);

        self.reset($grid);

        var i = 0;
        var classes = '';
        var rows = [];
        var isCarousel = $grid.hasClass('js-product-grid--carousel');
        var $row = $();
        var $container = $('<div class="js-quickshop-container product-quickshop-wrapper custom-collection-product-quickshop-wrapper" />');
        var $elements = $('.js-product-grid-item:visible', this);
        var elementsLength = $elements.length;
        var isRow = function (i, appendAfter, index, elementsLength) {
          return i === appendAfter || index + 1 === elementsLength;
        };
        var setLastElement = function (i, appendAfter, $element) {
          if (i === appendAfter) {
            $element.addClass('last'); // Currently brand CSS targets this rather than nth-of-type.
          }
        };

        $elements.each(function (index) {
          var $element = $(this);
          var colspan = $element.data('colspan') || 1;
          var appendAfter = $element.data('quickshop-after') || 3;

          classes += ' js-quickshop-container--' + $element.data('product-id');

          if (isCarousel) {
            return false;
          }

          if (isMobile) {
            $element.after($container.clone()).next().addClass(classes);

            classes = '';

            rows.push($element);
          } else {
            i += colspan;

            $row = $row.add(this);

            if (isRow(i, appendAfter, index, elementsLength)) {
              setLastElement(i, appendAfter, $element);

              $element.after($container.clone()).next().addClass(classes);

              rows.push($row);

              i = 0;
              $row = $();
              classes = '';
            }
          }
        });

        if (isCarousel) {
          var $slides = $('.slick-slide', this);

          rows = $slides.length ? [$slides] : [$elements];

          $grid.after($container.clone()).next().addClass(classes);
        }

        $grid.data('product-grid-rows', rows);

        $grids.add($grid);
      });
    },

    reset: function ($grid) {
      var $container = $grid.hasClass('js-product-grid--carousel') ? $grid.parent() : $grid;

      $('.js-quickshop-container', $container).remove();

      $('.js-custom-collection-product-brief-v1', $grid).removeClass('active');

      $('html').removeClass('custom-collection-quickshop-open');
    },

    setAvailableSkusString: function (availableSkusString, product) {
      var skus = [];

      if (availableSkusString) {
        _.each(_.map(availableSkusString.split(','), $.trim), function (skuID) {
          _.each(product.skus, function (sku) {
            if (sku.SKU_ID === skuID) {
              skus.push(sku);
            }
          });
        });

        if (skus.length) {
          return product.skus = skus;
        }
      }

      return product.skus;
    },

    setEvents: function () {
      var self = this;

      $(document).on('click', '.js-custom-collection-quickshop-trigger', function (event) {
        event.preventDefault();

        $(this).closest('.js-product').trigger('customCollection:quickshopLaunch');
      });

      $(this.context).on('customCollection:quickshopClose', '.js-product', function () {
        var productID = $(this).data('product-id');
        var $grid = $(this).closest('.product-grid');
        var $wrapper = $grid.closest('.product-grid-wrapper');
        var $container = $('.js-quickshop-container--' + productID, $wrapper);

        $('html').removeClass('custom-collection-quickshop-open');

        $container
          .removeClass('active ' + qsContainerActiveClasses)
          .find('.js-custom-collection-product-quickshop-v1')
          .remove();
      });

      $(this.context).on('customCollection:quickshopLaunch', '.js-product', function () {
        var $product = $(this);
        var productID = $product.data('product-id');
        var availableSkusString = $product.data('available-skus');
        var skuID = $product.data('sku-base-id');
        var product = window.prodcat.data.getProduct(productID);
        var $grid = $product.closest('.js-product-grid');
        var isCarousel = $grid.hasClass('js-product-grid--carousel');
        var $qsContext = isCarousel ? $grid.parent() : $grid;
        var $containers = $('.js-quickshop-container', $qsContext);
        var $container = $containers.filter('.js-quickshop-container--' + productID);
        var $gridItem = $product.closest('.js-product-grid-item');
        var $products = $('.js-custom-collection-product-brief-v1', $grid);
        var $active = $containers.filter('.active');
        var parent_template = 'custom_collection_mpp_product_layout_v1';
        var template = 'custom_collection_product_quickshop_uk_v1';
        var $qsProduct = $();
        var $productPowerReview = $();
        var rendered;

        self.isActive($product);

        product.skus = self.setAvailableSkusString(availableSkusString, product);

        if (!_.isUndefined(site.templates[parent_template])) {
          product = $.extend({}, site.templates[parent_template].data, product);
        }

        rendered = site.template.get({
          name: template,
          data: product
        });

        self.setTagging(productID);

        $active.hide();

        $('.js-custom-collection-product-quickshop-v1', $container).remove();

        $container.append(rendered).show();

        $qsProduct = $('.js-product', $container);

        $(document).trigger('product.init');

        Drupal.behaviors.customCollectionSkuListSizesV1.attach($qsProduct);

        if (skuID) {
          $qsProduct.trigger('product.skuSelect', [skuID]);
        }

        self.setPanels($container);

        $('.js-quickshop-close-inline', $container).on('click', function (event) {
          event.preventDefault();

          $product.trigger('customCollection:quickshopClose');
        });

        $products.removeClass('active');
        $product.addClass('active');

        $productPowerReview = $('.js-quickshop', self.context);
        $productPowerReview.trigger('products.init-start');
        $productPowerReview.trigger('product.init-pr');
        $(self.context).trigger('products.init-finish');

        self.setState($container, $grid, $gridItem, isCarousel);
      });

      $(document).on('grid.reflow', '.product-grid--quickshop', function () {
        self.initGrid($(this));
      });

      Unison.on('change', function (bp) {
        if (lastBP === 'large' && bp.name === 'medium' || lastBP === 'medium' && bp.name === 'large') {
          return;
        }

        lastBP = bp.name;

        self.initGrid($('.js-product-grid--quickshop'), bp.name);
      });
    },

    setState: function ($container, $grid, $gridItem, isCarousel) {
      var isCollectionTrayOpen = $('html').hasClass('custom-collection-quickshop-open');
      var $collectionTray = $('.js-custom-collections-product-tray-v1', self.context);
      var $landingWrapper = $('.js-custom-collection-landing-v1', self.context);
      var $stickyHeader = $('.page-header.is-sticky', self.context);
      var scrollOffset = -Math.abs((isCollectionTrayOpen ? $collectionTray.height() : 0) + ($landingWrapper.offset().top + $stickyHeader.height()));
      var scrollTop = $container.offset().top;
      var scrollSpeed = 400;
      var $carousel = $();
      var slideIndex = 0;
      var i = 0;

      // Determine which item is active in the row so css can position the arrow.
      // The arrow is inside of the quickshop rather than relative to the thumb
      // so that it is part of the expand/collapse animation.
      if (isCarousel) {
        $carousel = $('.js-product-carousel', $grid).first();
        slideIndex = $carousel.slick('slickCurrentSlide');

        i = $gridItem.data('slick-index') - slideIndex + 1;
      } else {
        i = $gridItem.prevUntil('.js-quickshop-container').filter('.js-grid-item:visible').length + 1;
      }

      if ($container.hasClass('active')) {
        $container.removeClass(qsContainerActiveClasses).addClass('active active--' + i);
      } else {
        $(this).removeClass(qsContainerActiveClasses).find('.js-custom-collection-product-quickshop-v1').remove();

        $container.addClass('active active--' + i);

        $('html').addClass('custom-collection-quickshop-open');
      }

      this.setScroll(scrollTop, scrollOffset, scrollSpeed);
    },

    setScroll: function (top, offset, speed) {
      $('html, body').animate({
        scrollTop: top + offset
      }, {
        duration: speed
      });
    },

    setPanels: function (context) {
      var $panelHeaders = $('.js-custom-collection-accordion__header-link', context);
      var animationSpeed = 300;

      $panelHeaders.not('.active-header').next().slideUp(animationSpeed);

      $('.js-custom-collection-accordion__header-link.active-header', context).next().slideDown(animationSpeed);

      $panelHeaders.click(function (event) {
        var $element = $(this);

        event.preventDefault();

        if ($element.hasClass('active-header')) {
          $element.next().slideUp(animationSpeed);
          $element.removeClass('active-header');
        } else {
          $element.addClass('active-header').next().slideDown();
        }
      });
    },

    isActive: function ($product) {
      if ($product.hasClass('active')) {
        $product.trigger('customCollection:quickshopClose');

        return;
      }
    },

    setTagging: function (productID) {
      if (!_.isUndefined(window.CATEGORY_ID)) {
        $(window).trigger('MPP:productQV', [window.CATEGORY_ID + '~' + productID]);
      }
    }
  };
})(jQuery, Drupal, window, document);
